<template>
  <div v-if="page_data" class="mb-3 order-address">
    <h2 class="m-0 page-title">{{ $t("orders.address_information") }}</h2>
    <hr />
    <b-row>
      <b-col md="6">
        <div class="info">
          <h3 class="sub-title mb-3">{{ $t("orders.shipping_address") }}</h3>
          <ul>
            <li>
              <strong>{{ $t("orders.address") }}</strong>
              <a
                :href="`http://maps.google.com/?q=${page_data.address.latitude},${page_data.address.longitude}`"
                target="_blank"
                ><span>{{ `${page_data.address.address_text}` }}</span>
              </a>
            </li>
            <li>
              <strong>{{ $t("orders.order_status") }}</strong>
              <b-form class="form-status">
                <b-form-select
                  :disabled="
                    !(globalPermission.orders && globalPermission.orders.update)
                  "
                  :options="status_order"
                  v-model="status_id"
                  text-field="name"
                  value-field="id"
                  @input="handleChangeStatus($event)"
                >
                </b-form-select>
                <b-badge
                  :variant="
                    status.name.toLowerCase() === 'delivered'
                      ? 'success'
                      : 'warning'
                  "
                  >{{ status.name }}</b-badge
                >
              </b-form>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col md="6">
        <div class="info">
          <h3 class="sub-title mb-3">
            {{ $t("orders.delivery_boy_information") }}
          </h3>
          <ul v-if="page_data.delivery_boy">
            <li>
              <strong>{{ $t("orders.phone_number") }}</strong>
              {{
                page_data.delivery_boy
                  ? page_data.delivery_boy.user.phone[0].phone
                  : "No Phone"
              }}
            </li>
            <li>
              <strong>{{ $t("orders.delivery_boy") }}</strong>
              {{
                page_data.delivery_boy
                  ? page_data.delivery_boy.user.first_name +
                    " " +
                    page_data.delivery_boy.user.last_name
                  : "No Delivery"
              }}
            </li>
          </ul>
          <strong v-else>{{
            $t("orders.delivery_boy_has_not_been_added_yet")
          }}</strong>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BBadge,
} from "bootstrap-vue";

export default {
  name: "AddressOrder",
  components: {
    BRow,
    BCol,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BBadge,
  },
  props: ["page_data"],
  data() {
    return {
      status_id: null,
      status: null,
      status_order: [
        {
          name: this.$t("orders.edit_order_status"),
          id: null,
          disabled: true,
        },
      ],
    };
  },
  created() {
    this.page_data.status.map((item) => {
      this.status_order.push(item);
    });
    this.handleStatus(this.status_order);
  },
  methods: {
    handleChangeStatus(item) {
      this.status = this.status_order[item];

      const data = {
        status_id: this.status_id,
      };
      this.$http
        .post(`admin/orders/${this.page_data.id}?_method=put`, data)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$helpers.makeToast(
              "success",
              res.data.message,
              res.data.message
            );
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleStatus(array) {
      array.map((item) => {
        if (item.active_date) {
          this.status = item;
          this.status_id = item.id;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./_Address.scss";
</style>
