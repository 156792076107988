<template>
  <Loading v-if="isLoading" />
  <div v-else class="show-order">
    <b-card>

      <OrdersInformation :page_data="order_data"/>

      <Address :page_data="order_data"/>

      <ItemsOrder :page_data="order_data"/>

      <OrderSubtotal :page_data="order_data"/>

    </b-card>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";


import OrdersInformation from "@/components/Orders/OrdersInformation/OrdersInformation.vue";
import Address from "@/components/Orders/Address/Address.vue";
import ItemsOrder from "@/components/Orders/ItemsOrder/ItemsOrder.vue";
import OrderSubtotal from "@/components/Orders/OrderSubtotal/OrderSubtotal.vue";


export default {
  name: "ShowOrder",
  components: {
    BCard,
    Loading,
    OverlayComponent,
    OrdersInformation,
    Address,
    ItemsOrder,
    OrderSubtotal
  },
  data() {
    return {
      isPending: null,
      isLoading: true,
      overlayLoading: false,
      current_id: null,
      order_data: null,
    };
  },
  created() {
    this.current_id = this.$route.params.id;
    this.getOrderData();
  },
  methods: {
    async getOrderData() {
      try {
        const response = (
          await this.$http.get(`admin/orders/${this.current_id}`)
        ).data.data;
        this.order_data = response;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
