<template>
  <div v-if="page_data" class="mb-3">
    <h2 class="m-0 page-title">
      {{ $t("orders.order_&_account_information") }}
    </h2>
    <hr />
    <b-row>
      <b-col md="6">
        <div class="info">
          <h3 class="sub-title mb-3">{{ $t("orders.order_information") }}</h3>
          <ul>
            <li>
              <strong>{{ $t("orders.order_number") }}</strong>
              {{ page_data.order_code }}
            </li>
            <li>
              <strong>{{ $t("orders.order_date") }}</strong>
              {{ $helpers.dateTimeInFormat(page_data.created_at) }}
            </li>
            <li>
              <strong>{{ $t("orders.order_time") }}</strong>
              {{ $helpers.timeInFormat(page_data.created_at) }}
            </li>
            <li>
              <strong>{{ $t("orders.payment_status") }}</strong>
              <b-form class="form-status">
                <b-form-select
                  :disabled="
                    !(globalPermission.orders && globalPermission.orders.update)
                  "
                  :options="status_payment"
                  v-model="payment_status"
                  text-field="name"
                  value-field="value"
                  @input="handleChangeStatus"
                >
                </b-form-select>
              </b-form>
            </li>
            <li>
              <strong>{{ $t("orders.payment_method") }}</strong>
              {{ page_data.payment_method.name }}
              <span v-if="page_data.payment_method.id === 3">
                <a
                  target="_blank"
                  :href="page_data.bank_transfer_attachment.path"
                >
                  <img
                    src="@/assets/images/pages/bank.svg"
                    alt="Bank Transfer"
                  />
                  <span>{{ $t("orders.view_bank_transfer") }}</span>
                </a>
              </span>
              <span v-if="page_data.payment_method.id === 2">
                <!--                <a :href="page_data.bank_transfer_attachment.path">-->
                <!--                  <span>{{ $t("orders.transaction_id") }}</span>-->
                <!--                </a>-->
              </span>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col md="6">
        <div class="info">
          <h3 class="sub-title mb-3">
            {{ $t("orders.customer_information") }}
          </h3>
          <ul>
            <li>
              <strong>{{ $t("orders.customer_name") }}</strong>
              {{
                page_data.customer.first_name +
                " " +
                page_data.customer.last_name
              }}
            </li>
            <li>
              <strong>{{ $t("orders.customer_email") }}</strong>
              {{ page_data.customer.email }}
            </li>
            <li>
              <strong>{{ $t("orders.customer_phone") }}</strong>
              {{ page_data.customer.phone[0].phone }}
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormSelect, BBadge } from "bootstrap-vue";

export default {
  name: "OrdersInformation",
  components: {
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormSelect,
  },
  props: ["page_data"],
  created() {
    this.payment_status = this.page_data.is_paid ? 1 : 0;
  },
  data() {
    return {
      payment_status: null,
      status_payment: [
        {
          name: this.$t("orders.edit_payment_status"),
          value: null,
          disabled: true,
        },
        {
          name: this.$t("orders.paid"),
          value: 1,
        },
        {
          name: this.$t("orders.unpaid"),
          value: 0,
        },
      ],
    };
  },
  methods: {
    handleChangeStatus() {
      const data = {
        is_paid: this.payment_status,
      };
      this.$http
        .post(`admin/orders/${this.page_data.id}?_method=put`, data)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$helpers.makeToast(
              "success",
              res.data.message,
              res.data.message
            );
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "./_OrdersInformation";
</style>
