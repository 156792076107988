<template>
  <div v-if="page_data">
    <b-row>
      <b-col offset-md="8" md="4">
        <div class="info">
          <ul>
            <li>
              <strong>{{ $t("orders.subtotal") }}</strong>
              
              {{ page_data.grand_total }}
            </li>
            <li>
              <strong>{{ $t("orders.shipping_cost") }}</strong>
              {{ page_data.shipping_cost }}
            </li>
            <li>
              <strong>{{ $t("orders.taxes") }}</strong>
              {{ page_data.vat_amount }}
            </li>
            <hr />
            <li>
              <strong>{{ $t("orders.total") }}</strong>
              {{ page_data.final_total }}
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  name: "OrderSubtotal",
  components: {
    BRow,
    BCol,
  },
  props: ["page_data"],
};
</script>

<style lang="scss">
@import "./_OrderSubtotal";
</style>
